<template>
  <div class="orga" v-if="currentRoute">
    <stage :stageLabel="$i18n.locale === 'de' ? 'Organisationsberatung' : 'Organisational Consulting'" :big="true"
      :title="currentRoute.title.rendered" />
    <introduction fontWeight="normal" :contact="currentRoute.acf.Contact" :image="currentRoute.acf.Contact.image"
      :subline="currentRoute.title.rendered" :maintext="currentRoute.acf.description" />
    <div class="orga__content">
      <div v-if="currentRoute.acf.blocks" class="orga__blocks">
        <inline-blocks :service="true" :blocks="currentRoute.acf.blocks" />
      </div>
    </div>
    <introduction fontWeight="bold" :maintext="currentRoute.acf.heading" class="orga__offer-heading" />
    <div ref="projectlist" class="orga__content">
      <template v-if="pagedOffers.length > 0">
        <projects :items="pagedOffers" />
        <pagination @change-current="setCurrentPage" :currentPage="currentPage"
          :totalPages="Number(getOfferListProps.totalPages)" />
      </template>
      <div v-else ref="projectlist" class="orga__content orga__content--empty">
        <h2>{{
          $i18n.locale === 'de' ? 'Aktuell sind keine Angebote verfügbar.' : 'No offers are currently available.'
        }}
        </h2>
      </div>
      <testimonial :image="currentRoute.acf.testimonial.image" :text="currentRoute.acf.testimonial.text"
        :author="currentRoute.acf.testimonial.position" />
      <fact-testimonial :image="currentRoute.acf.fact.image.url"
        :alt="currentRoute.acf.fact.image.alt || currentRoute.acf.fact.image.name" :quote="currentRoute.acf.fact.quote"
        :quoteId="3" :fact="currentRoute.acf.fact.fact" :color-theme="currentRoute.acf.fact.colorTheme"
        :reversed="currentRoute.acf.fact.reversed"
        :textQuote="currentRoute.acf.fact.text_quote ? currentRoute.acf.fact.text_quote : ''"
        :author="currentRoute.acf.fact.author ? currentRoute.acf.fact.author : ''" />
      <tag-posts :header="currentRoute.acf.tagPostsHeader" :posts="tagPosts" :route="$route.name" />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Stage from '@/components/stage.vue'
import Introduction from '@/components/introduction.vue'
import Projects from '@/components/jobs-blocks.vue'
import { mapActions, mapGetters } from 'vuex'
import Pagination from '../components/pagination.vue'
import FactTestimonial from '@/components/fact-testimonial.vue'
import TagPosts from '@/components/tag-posts.vue'
import inlineBlocks from '@/components/inline-blocks.vue'
import Testimonial from '@/components/testimonial.vue'

export default {
  name: 'Orga',
  components: {
    Stage,
    Introduction,
    Projects,
    Pagination,
    FactTestimonial,
    TagPosts,
    inlineBlocks,
    Testimonial
  },
  data() {
    return {
      currentPage: 1,
    }
  },
  computed: {
    ...mapGetters([
      'getOffers',
      'getOfferListProps',
      'getFetched',
    ]),
    currentRoute() {
      const getFetched = this.getFetched[this.$route.name];
      return getFetched;
    },
    pagedOffers() {
      return this.getOffers.slice((this.currentPage - 1) * 10, this.currentPage * 10)
    },
    tagPosts() {
      if (this.currentRoute) {
        return this.currentRoute.postTags ? this.currentRoute.postTags : [];
      }
      return [];
    }
  },
  created() {
    if (!this.currentRoute) {
      this.fetchCurrentPage(this.$route.name);
    }
    if (this.getOffers.length === 0) {
      this.fetchOffers(this.currentPage)
    }
  },
  watch: {
    currentPage(cur, old) {
      if (Number(this.getOffersListProps.totalItems) > old * 10 && this.getOffers.length !== Number(this.getOffersListProps.totalItems)) {
        this.fetchOffers(cur)
      }
      this.scrollTo(this.$refs.projectlist);
    }
  },
  methods: {
    ...mapActions([
      'fetchOffers',
      'fetchCurrentPage',
    ]),
    setCurrentPage(val) {
      if (val <= this.getOffersListProps.totalPages && val > 0) {
        this.currentPage = val
      }
    },
    scrollTo(ref) {
      window.scrollTo({
        top: ref.offsetTop - 150,
        left: 0,
      });
    }
  }
}
</script>
<style lang="scss" scoped>
.orga {
  margin-top: 86px;
  padding: 0 .8rem;

  &__content {
    max-width: 1140px;
    margin: auto;

    &--empty {
      text-align: center;
      margin-bottom: 86px;
    }
  }

  &__blocks {
    max-width: 800px;

    @media (min-width: 1024px) {
      padding: 65px 65px 0 110px;
      margin-top: -100px;
    }
  }

  &__sectionhead {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-family: "neue-plak";
    font-weight: 500;
    color: #182952;
    margin: 0 0 65px 0;

    &--left {
      justify-content: flex-start;
    }
  }

  &__heading {
    margin-bottom: 0;
  }
}
</style>
