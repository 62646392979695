<template>
  <div class="thema" v-if="currentRoute">
    <stage :stageLabel="getSubCat" :title="currentRoute.title.rendered"/>
    <introduction
      fontWeight="normal"
      :subline="currentRoute.title.rendered"
      :contact="currentRoute.acf.Contact"
      :image="currentRoute.acf.Contact.image"
      :maintext="currentRoute.acf.description"
    />
    <div class="thema__content">
      <h3 class="thema__sectionhead">{{currentRoute.acf.more}}</h3>
      <inline-blocks :blocks="currentRoute.acf.blocks"/>
      <fact-testimonial
        :image="currentRoute.acf.fact.image.url"
        :alt="currentRoute.acf.fact.image.alt"
        :quote="currentRoute.acf.fact.quote"
        :fact="currentRoute.acf.fact.fact"
        :color-theme="currentRoute.acf.fact.colorTheme"
        :reversed="currentRoute.acf.fact.reversed"
        :textQuote="currentRoute.acf.fact.text_quote"
        :author="currentRoute.acf.fact.author"
      />
    </div>
    <tag-posts :header="$i18n.locale === 'de' ? 'Aktuelles' : 'What´s new'" :posts="tagPosts" :route="$route.params.name"/>
  </div>
</template>

<script>
// @ is an alias to /src
import Stage from '@/components/stage.vue'
import Introduction from '@/components/introduction.vue'
import InlineBlocks from '@/components/inline-blocks.vue'
import FactTestimonial from '@/components/fact-testimonial.vue'
import TagPosts from '@/components/tag-posts.vue'

import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'ThemaDetail',
  components: {
    Stage,
    Introduction,
    InlineBlocks,
    FactTestimonial,
    TagPosts,
  },
  computed: {
    ...mapGetters([
        'getFetched',
        'getCats'
    ]),
    currentRoute(){
      const getFetched =  this.getFetched[this.$route.params.name];
      return getFetched;
    },
    tagPosts(){
      if (this.currentRoute) {
        return this.currentRoute.postTags ? this.currentRoute.postTags : [];
      }
      return [];
    },
    postCats() {
        return this.currentRoute.categories;
    },
    getSubCat() {
        const postCats = cat => {
            if(this.postCats.includes(cat.id) && cat.parent !== 0) {
                return cat;
            }
        }
        return this.getCats.length ? this.getCats.filter(postCats)[0].name : '';
    }
  },
  created() {
      if(!this.currentRoute) {
          this.fetchCurrentPost(this.$route.params.name);
      }
  },
  watch: {
      currentRoute(val) {
        if(!this.getCats[val.categories[0]]) {
          this.fetchCats();
        }
      }
  },
  methods: {
    ...mapActions([
          'fetchCurrentPost',
          'fetchCats'
    ]),
  }
}
</script>
<style lang="scss" scoped>
.thema {
  margin-top: 86px;
  padding: 0 .8rem;
  &__content {
    max-width: 1140px;
    margin: auto;
    padding:  0 .8rem;
  }
  &__sectionhead {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-family:"neue-plak";
    font-weight: 500;
    color: #182952;
    margin-bottom: 65px;
  }
  &__note {
    font-size: 15px;
    font-family: 'freight-text-pro';
    margin-left: .4rem;
    color: #666666;
    text-decoration: none;
  }
}
</style>
